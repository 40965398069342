#outerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}


#purchaseHeader {
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 3rem 0rem 2rem 0rem;
}
.purchaseBody {
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 3rem 0rem 2rem 0rem;
}
.purchaseBodyX {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; /* Align items at the top */
  width: 100%;
  max-width: 1200px;
  margin: auto;
  padding: 3rem 0rem 2rem 0rem;
}

.purchaseColumn {
  flex: 1; /* Ensures both columns take equal width */
  min-width: 300px; /* Prevents columns from shrinking too much */
  padding:1rem;
}

.purchaseColumn:last-child {
  margin-left: 2rem; /* Adds spacing between the two divs */
}

#purchaseHeaderText {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

#backButton {
  width: 100px;
  height: 30px;
  background-color: white;
  border: none;
}

.listText{
  font-size:1.4rem; 
}

#purchaseForm {
  font-family: Mulish;
  font-weight: 700;
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  gap: 1rem;  
  padding: 3rem 0rem 2rem 0rem;

}

.purchaseInputContainer {
  display: flex;
  justify-content: space-between;
  max-width: 500px;
}

.purchaseCountInputContainer {
  display: flex;
  gap: 1rem;
}

.countInput {
  width: 60px;
  height: 30px;
}

.fontheader{
  font-family: "NexaBold";
  font-size: "26px";
  align-self: center;
  margin-top:30px;
}

.subtitle{
  font-family: "Mulish";
  font-size: "17px";
  font-weight: "700"
}

#submitButtonContainer {
  display: flex;
  flex-direction: column;
  padding: 2rem 0rem 2rem 0rem;
  gap: 1rem;
}

#purchaseSubmitButton {
  font-family: Mulish;
  font-weight: bold;
  width: 140px;
  height: 40px;
  background-color: #e7b33e;
  border: none;
  border-radius: 5px;
  font-size: 1.5rem;
}
.formButton {
  font-family: Mulish;
  font-weight: bold;
  width: 100px;
  height: 40px;
  background-color: #e7b33e;
  border: none;
  border-radius: 5px;
  font-size: 1.5rem;
}

.purchaseParagraph{
  font-family: "Mulish";
  font-size: "17px";
  font-weight: "700";
}